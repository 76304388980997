(function($)
{
	if (!$.sdc395)
	{
		$.sdc395 = new Object();
	};

	$.sdc395.scrollme = function(el, targets, options)
	{
		// To avoid scope issues, use 'self' instead of 'this'
		// to reference this class from internal events and functions.
		var self = this;

		// Access to jQuery and DOM versions of element.
		self.$el = $(el);
		self.el = el;

		// Add a reverse reference to the DOM object.
		self.$el.data("sdc395.scrollme", self);

		self.init = function()
		{
			self.options = $.extend({}, $.sdc395.scrollme.defaultOptions, options);
			setInterval(function() { window.requestAnimationFrame(self.update); }, 100);
		}

		self.update = function()
		{
			targets.each(function()
			{
				start = self.$el.offset().top - $(window).scrollTop();
				end = start + self.$el.height();
			
				scroll = ($(window).scrollTop() - start) / (end - start);
				
				//console.log("Scroll = " + scroll);
				
				scroll = 1 - Math.max(0, Math.min(scroll, .2));
				scroll = scroll.toFixed(2);
				
				//console.log("scroll = " + scroll);
				
				//$(this).css({"opacity": scroll});
				$(this).css({"background-color": "rgba(21, 22, 22, " + scroll + ")"});
			});
		}

		// Initialise.
		self.init();
	};

	$.sdc395.scrollme.defaultOptions =
	{
	};

	$.fn.sdc395_scrollme = function(targets, options)
	{
		return this.each(function()
		{
			(new $.sdc395.scrollme(this, targets, options));
		});
	};

})(jQuery);